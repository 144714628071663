import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";

import Home from "./pages/Home.js";
// import Events from "./pages/Events.js";
import EventDetails from "./pages/EventDetails.js";
// import News from "./pages/News.js";
import ChallengeGrants from "./pages/ChallengeGrants.js";
import StudentProjects from "./pages/StudentProjects.js";
// import People from "./pages/People.js";
import InnovateShowcase from "./pages/InnovateShowcase.js";
import FarmBotChallenge from "./pages/FarmBotChallenge.js";
import ContactUs from "./pages/ContactUs.js";
import NotFound from "./pages/NotFound.js";
import Mission from "./pages/Mission.js";
import NewsDetail from "./components/NewsDetail.js";
import InstagramPosts from "./components/InstagramPost.js";
import SupportUs from "./pages/SupportUs.js";
import TheFarm from "./pages/ESF.js";
import F3 from "./pages/F3.js"

import "./global.css";

/*
  Wrapping in StyledEngineProvider is required for overriding MUI
  styles with CSS. See https://mui.com/guides/interoperability/#controlling-priority-3
  for more information.

  (e.g. So we can target Mui Components with CSS like this:
    .class .MuiButton-root {
      background-color: red;
    }
  )

  We use react-browser-router for routing. See https://reactrouter.com/en/main/router-components/browser-router
  for more information.

*/

// redirects for new site
const Events = () => {
	return(<><div><meta http-equiv="Refresh" Content="0; url=https://vista.ucmerced.edu/f3-events/" /></div></>);
};
const News = () => {
	return(<><div><meta http-equiv="Refresh" Content="0; url=https://vista.ucmerced.edu/f3-news/" /></div></>);
};
const People = () => {
	return(<><div><meta http-equiv="Refresh" Content="0; url=https://vista.ucmerced.edu/f3-team/" /></div></>);
};

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<F3 />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Events/:id" element={<EventDetails />} />
          <Route path="/News" element={<News />} />
          <Route path="/ChallengeGrants" element={<ChallengeGrants />} />
          <Route path="/People" element={<People />} />
          <Route path="/Mission" element={<Mission />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/StudentProjects" element={<StudentProjects />} />
          <Route path="/InnovateShowcase" element={<InnovateShowcase />} />
          <Route path="/FarmBotChallenge" element={<FarmBotChallenge />} />
          <Route path="/ExperimentalSmartFarm" element={<TheFarm/>} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" exact component={Events} />
          <Route path="/Events/:slug" component={<EventDetails/>} />
          <Route path="/News" element={<News />} />
          <Route path="/News/:slug" element={<NewsDetail />} />
          <Route path="/SupportUs" element={<SupportUs/>} />
        </Routes>
      </BrowserRouter>
    </StyledEngineProvider>
  );
}

export default App;
